/* Section
---------------------*/
.section {
  padding: 100px 0;
  position: relative;
  @include down-md {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  @include down-sm {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.m-15px-tb {
  margin-top: 15px;
  padding-bottom: 15px;
}

/* Title
-----------------------------------*/
.title {
  margin-bottom: 35px;
  @include down-sm {
    margin-bottom: 25px;
  }
  h3 {
    margin: 0;
    color: $px-white;
    position: relative;
    z-index: 1;
    font-weight: 600;
    font-size: 30px;
    display: inline-block;
    vertical-align: top;
    .theme-light & {
      color: $px-dark;
    }
    &:after {
      content: "";
      width: 45px;
      height: 45px;
      border-radius: 50%;
      background: $px-theme;
      position: absolute;
      top: -8px;
      left: -15px;
      z-index: -1;
      -webkit-animation: slide-both-sidee 4s infinite both;
      animation: slide-both-side 4s infinite both;

      .theme-light & {
        background: rgba($px-theme, 0.5);
      }
      @include down-md {
        top: -4px;
        left: 0;
      }
    }
  }
}

.separated {
  padding-top: 80px;
  padding-bottom: 80px;
  background-repeat: repeat-x;
  background-size: auto 6px;
  background-position: center;
  .theme-light & {
  }
  @include down-md {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  @include down-sm {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

/* Home Banner
-----------------------------------*/
.home-banner {
  position: relative;
  overflow: hidden;
  &:after {
    content: "";
    position: absolute;
    right: -20vh;
    bottom: -20vh;
    width: 120vh;
    height: 120vh;
    border-radius: 50%;
    background: rgba($px-black, 0.1);
    z-index: -1;
    .theme-light & {
      background: $px-gray;
    }
  }
  .container {
    position: relative;
    z-index: 1;
  }

  .hb-top-fixed {
    position: absolute;
    padding: 30px 50px 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;
    @include down-sm {
      display: none !important;
    }
    .hb-info {
      label,
      a {
        margin: 0;
        color: $px-white;
        font-weight: 400;
        font-size: 14px;
        .theme-light & {
          color: $px-dark;
        }
        + a {
          margin-left: 40px;
        }
      }
      a {
        transition: 0.3s;
        &:hover {
          color: $px-theme;
        }
      }
    }
    .hb-lang {
      margin-left: auto;
      li {
        + li {
          margin-left: 10px;
        }
        a {
          color: $px-white;
          font-size: 14px;
          padding: 5px 8px;
          .theme-light & {
            color: $px-dark;
            font-weight: 500;
          }
        }
        &:hover,
        &.active {
          background: $px-theme;
          a {
            .theme-light & {
              color: $px-white;
            }
          }
        }
      }
    }
  }
  .hb-me {
    position: absolute;
    right: 0;
    width: 50vw;
    height: 100%;
    background-size: cover;
    top: 0;
    background-repeat: no-repeat;
    background-position: top left;

    @media screen and (max-width: 1199px) {
      width: 84vw;
      opacity: 0.6;
    }
    @include down-md {
      opacity: 0.6;
      width: 80vw;
    }
    @include down-sm {
      opacity: 0.4;
      width: 102vw;
    }
  }

  .full-screen {
    min-height: 100vh;
    @media screen and (max-width: 767px) {
      min-height: auto;
      padding: 120px 0;
    }
  }
  .type-box {
    padding-top: 90px;
    padding-bottom: 50px;
    h6 {
      color: $px-white;
      font-size: 20px;
      font-weight: 400;
      margin: 0 0 20px;
      .theme-light & {
        color: $px-dark;
      }
      @include down-sm {
        font-size: 18px;
        margin: 0 0 10px;
      }
    }
    h1 {
      font-size: 80px;
      font-weight: 700;
      line-height: 1;
      margin: 0 0 15px;
      color: $px-white;
      .theme-light & {
        color: $px-dark;
      }
      @include down-lg {
        font-size: 70px;
      }
      @include down-sm {
        font-size: 36px;
      }
    }
    .lead {
      color: $px-white;
      font-weight: 500;
      font-size: 30px;
      text-transform: capitalize;
      margin-bottom: 15px;
      .theme-light & {
        color: $px-theme;
        line-height: 1.2;
      }
      @include down-sm {
        font-size: 20px;
        margin-bottom: 15px;
      }
    }
    .desc {
      max-width: 450px;
    }
    .btn-bar {
      padding-top: 10px;
      a {
        color: $px-white;
        font-size: 25px;
        line-height: 1;
        font-weight: 400;
        position: relative;
        text-decoration: none;
        padding-left: 0;
        @include transition(ease all 0.35s);
        .theme-light & {
          color: $px-dark;
        }
        @include down-sm {
          font-size: 20px;
        }
        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          margin: auto;
          width: 0;
          height: 2px;
          background: currentColor;
          @include transition(ease all 0.35s);
        }
        &:hover {
          padding-left: 45px;
          &:after {
            width: 40px;
          }
        }
      }
    }
  }
}
.home-banner.home-banner-two {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  &::after {
    display: none;
  }
  @media screen and (max-width: 1400px) {
    background-position: 100% 100%;
  }
  @media screen and (max-width: 1024px) {
    background-position: 78% 100%;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
    }
    .full-screen {
      min-height: 100vh;
    }
    .type-box {
      h6,
      h1,
      p {
        color: #fff;
      }
    }
  }
}
.loop-text {
  line-height: 1 !important;
}
/* About
-----------------------------------*/
.about-me {
  @include down-md {
    margin-bottom: 40px;
  }
  .img-in {
    border-radius: 50%;
    max-width: 356px;
    max-height: 358px;
    margin: auto;
    overflow: hidden;
    border: 5px solid $px-white;
    .theme-light & {
      border: 5px solid $px-theme;
    }
  }
  .img {
    position: relative;
  }
  .info {
    text-align: center;
    padding-top: 40px;
    color: $px-white;
    .theme-light & {
      color: $px-dark;
    }
    h3 {
      font-weight: 500;

      font-size: 30px;
    }
    p {
      margin: 0;
      font-size: 16px;
    }
  }
  .social-icons {
    position: absolute;
    bottom: -15px;
    left: 0;
    right: 0;
    a {
      width: 35px;
      height: 35px;
      line-height: 31px;
      background: $px-white;
      border: 2px solid $px-white;
      color: $px-dark;
      text-align: center;
      border-radius: 50%;
      position: relative;
      margin: 0 5px;
      @include transition(ease all 0.35s);
      .theme-light & {
        background: $px-dark;
        color: $px-white;
      }
      @include scale(1);
      i {
        line-height: inherit;
      }
      &:nth-child(1) {
        top: -25px;
      }
      &:nth-child(2) {
        top: -8px;
      }
      &:nth-child(4) {
        top: -8px;
      }
      &:nth-child(5) {
        top: -25px;
      }
      &:hover {
        @include scale(1.1);
        background: $px-theme;
        color: $px-white;
      }
    }
  }
}

.about-info {
  .title {
    margin-bottom: 30px;
  }
  .info-list {
    padding-top: 15px;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }
    li {
      padding: 4px 0;
    }
    label {
      color: $px-white;
      margin: 0;
      font-weight: 500;
      padding-right: 10px;
      .theme-light & {
        color: $px-dark;
      }
    }
  }
}

/* Feature Box
-----------------------------------*/
.feature-box-01 {
  background: $px-dark;
  padding: 40px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  .theme-light & {
    background: $px-white;
  }
  &:before {
    content: "";
    position: absolute;
    top: -220px;
    left: -220px;
    width: 200px;
    height: 200px;
    border-radius: 0;
    z-index: -1;
    background: $px-dark;
    transition: 0.5s ease-in-out;
  }
  &:after {
    content: "";
    position: absolute;
    top: -72px;
    left: -40px;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    z-index: -2;
    background: $px-theme;
    transition: 0.5s ease-in-out;
  }
  .icon {
    line-height: 60px;
    font-size: 40px;
    color: $px-white;
  }
  .feature-content {
    padding-top: 50px;
  }
  h5 {
    margin-bottom: 15px;
    color: $px-white;
    transition: 0.5s ease-in-out;
    .theme-light & {
      color: $px-dark;
    }
  }
  p {
    margin: 0;
    transition: 0.5s ease-in-out;
  }
  &:hover {
    .theme-light & {
      color: $px-white;
      h5 {
        color: $px-white;
      }
    }
    &:before {
      top: -72px;
      left: -40px;
      border-radius: 50%;
    }
    &:after {
      content: "";
      width: 120%;
      left: -10%;
      top: -10%;
      height: 120%;
      border-radius: 0;
      @include transition(cubic-bezier(0.39, 0.58, 0.57, 1) all 0.55s);
    }
    .icon,
    h5,
    p {
      animation: slideTop 0.5s ease-in-out 0s 1 normal none running !important;
    }
  }
}

.feature-box-02 {
  background: $px-dark;
  padding: 15px;
  border-radius: 10px;
  overflow: hidden;
  transition: 0.3s;
  &:hover {
    transform: translateY(-5px);
  }
  .theme-light & {
    background: $px-white;
  }
  .icon {
    width: 70px;
  }
  .media-body {
    padding-left: 15px;
  }
  h6 {
    color: $px-white;
    margin: 0 0 5px;
    font-size: 18px;
    .theme-light & {
      color: $px-dark;
    }
  }
  p {
    margin: 0;
    font-size: 14px;
  }
}

/* testimonial
-----------------------------------*/
.testimonial-01 {
  background: $px-dark;
  padding: 35px;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 15px;
  margin-bottom: 25px;
  .theme-light & {
    background: $px-white;
  }
  .avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    @include down-sm {
      width: 50px;
      height: 50px;
    }
  }
  .media-body {
    padding-left: 25px;
    @include down-sm {
      padding-left: 15px;
    }
  }
  h6 {
    color: $px-white;
    margin: 0 0 5px;
    .theme-light & {
      color: $px-dark;
    }
  }
  span {
    font-size: 13px;
  }
}

/* Experience
-----------------------------------*/
.resume-box {
  padding-top: 15px;
}
.resume-row {
  background: $px-dark;
  padding: 35px;
  border-radius: 15px;
  .theme-light & {
    background: $px-gray;
  }
  + .resume-row {
    margin-top: 30px;
  }
  h6 {
    color: $px-white;
    font-weight: 500;
    font-size: 20px;
    .theme-light & {
      color: $px-dark;
    }
  }
  .rb-left {
    min-height: 100%;
    @include up-sm {
      border-right: 1px solid rgba($px-white, 0.05);
      .theme-light & {
        border-right: 1px solid rgba($px-dark, 0.05);
      }
    }
    @include down-sm {
      margin-bottom: 25px;
    }
    h6 {
      margin-bottom: 5px;
    }
    label {
      font-size: 14px;
      font-style: italic;
    }
    p {
      margin-top: 5px;
      margin-bottom: 8px;
      line-height: normal;
    }
    .rb-time {
      display: inline-block;
      padding: 5px 10px;
      border-radius: 3px;
      color: $px-white;
      background: $px-theme;
      font-size: 12px;
      font-weight: 500;
    }
  }
  .rb-right {
    p {
      margin-bottom: 0;
    }
  }
}

.skill-lt {
  position: relative;
  padding-left: 80px;
  .count-inner {
    font-size: 30px;
    font-weight: 500;
    line-height: 1;
    color: $px-white;
    left: 0;
    top: 8px;
    position: absolute;
    .theme-light & {
      color: $px-dark;
    }
  }
  &:not(:first-child) {
    margin-top: 45px;
  }
  h6 {
    font-size: 15px;
    margin: 0 0 10px;
    font-weight: 400;
    color: $px-white;
    .theme-light & {
      color: $px-dark;
    }
  }
  .skill-bar {
    position: relative;
    background: rgba($px-white, 0.2);
    .theme-light & {
      background: rgba($px-dark, 0.1);
    }

    .skill-bar-in {
      width: 80px;
      position: relative;
      @include transition(ease all 0.55s);
      height: 10px;
      background: $px-theme;
    }
  }
}
.aducation-box {
  margin: 0;
  padding: 35px;
  border-radius: 10px;
  background: $px-dark;
  list-style: none;
  .theme-light & {
    background: $px-gray;
  }
  @include down-md {
    margin-bottom: 10px;
  }
  p {
    margin: 0;
  }
  h6 {
    color: $px-white;
    margin: 5px 0;
    .theme-light & {
      color: $px-dark;
    }
  }
  li {
    + li {
      margin-top: 25px;
    }
  }
}

/* Contact Us
-----------------------------------*/
.contact-info {
  @include down-md {
    margin-bottom: 20px;
  }
  h4 {
    font-size: 34px;
    color: $px-white;
    font-weight: 500;
    .theme-light & {
      color: $px-dark;
    }
  }
  p {
    font-size: 16px;
  }
  ul {
    margin: 0;
    padding: 10px 0 0;
    list-style: none;
  }
  li {
    font-size: 18px;
    font-weight: 500;
    color: $px-white;
    line-height: 1.3;
    position: relative;
    .theme-light & {
      color: $px-dark;
    }
    i {
      width: 30px;
      text-align: center;
      color: $px-theme;
      font-size: 22px;
      font-weight: 600;
    }
    span {
      padding-left: 15px;
    }
    + li {
      padding-top: 20px;
    }
  }
}
.contact-form {
  @include down-md {
    padding-bottom: 20px;
  }
  h4 {
    font-weight: 500;
    font-size: 22px;
    margin-bottom: 20px;
    .theme-light & {
      color: $px-dark;
    }
  }
  .form-control {
    color: $px-white;
    background: none;
    border: 1px solid rgba($px-white, 0.3);
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
    height: calc(2em + 0.75rem + 2px);
    &.invalid {
      border-color: #dc3545 !important;
    }
    &.theme-light {
      color: $px-dark;
      border: 1px solid rgba($px-dark, 0.3);
      background: $px-white;
    }
  }
  textarea.form-control {
    height: auto;
  }
}

.google-map {
  margin-top: 50px;

  iframe {
    width: 100%;
    height: 350px;
    border: none !important;
  }
  .theme-light & {
    border: 5px solid $px-white;
  }
  @include down-sm {
    margin-top: 15px;
  }
  .embed-responsive-21by9 {
    filter: grayscale(1);
    -webkit-filter: grayscale(1);
    &:before {
      padding-top: 30%;
      @include down-sm {
        padding-top: 55%;
      }
    }
  }
}

.fixd-btn {
  background: #bc0609;
  padding: 9px 34px;
  display: inline-block;
  color: #fff;
  font-weight: 600;
  border-radius: 60px;
  transition: 0.3s;
  &:hover {
    color: #fff;
    transform: translateY(-5px);
  }
}

.slider-four {
  .loop-text {
    color: $px-dark !important;
    @media screen and (max-width: 1024px) {
      color: #fff !important;
    }
  }
  .mr-4 {
    margin-right: 20px;
  }
  @media screen and (max-width: 1024px) {
    &.home-banner.home-banner-two::before {
      background: rgba(0, 0, 0, 0.53);
    }
    .mr-4 {
      margin-right: 15px;
    }
    .px-btn {
      padding: 0 20px;
    }
  }

  &.bg-normal {
    @media screen and (max-width: 1024px) {
      &.home-banner.home-banner-two::before {
        background: #fff;
      }
      .type-box h6,
      .type-box h1,
      .type-box p,
      .loop-text {
        color: $px-dark !important;
      }
    }
    @media screen and (min-width: 767px) and (max-width: 1024px) {
      .type-box h1 {
        font-size: 48px;
      }
    }
  }
}
.go-to-next {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  width: 35px;
  margin: auto;
  z-index: 1;
  text-align: center;
  a {
    width: 35px;
    height: 35px;
    position: relative;
    display: inline-block;
    background: #d9832e;
    border-radius: 50%;
    animation: down 1.3s linear infinite;
    -webkit-animation: down 1.3s linear infinite;
    span {
      border-top: 1px solid #ffffff;
      border-right: 1px solid #ffffff;
      width: 10px;
      height: 10px;
      position: absolute;
      top: -6px;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      -moz-transform: rotate(135deg);
      -o-transform: rotate(135deg);
      -ms-transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      transform: rotate(135deg);
    }
  }
}
@keyframes down {
  0% {
    top: 5px;
    opacity: 0;
  }
  30% {
    top: 15px;
    opacity: 1;
  }
  100% {
    top: 25px;
    opacity: 0;
  }
}

// for particles
.frame-layout__particles {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  #tsparticles {
    height: 100%;
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
}
